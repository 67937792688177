import {Injectable} from '@angular/core'
import {MinionReport} from '../guardian/sign/sign.component'

export const KUNDSKYDD_URL = 'https://www.sparbankensyd.se/om-oss/sakerhet-kundskydd/kundkannedom-och-kundskydd'
export const KUNDSKYDD_PRIVAT_URL = 'https://www.sparbankensyd.se/om-oss/sakerhet-kundskydd/kundkannedom-privatkund'

export interface ICountry {
  name: string
  2: string
  3: string
  n: number
  r?: number
}

export interface IBankService {
  key: string
  title: string
  active: boolean
}

export const getDefaultReport = (): MinionReport => ({
  general: {
    citizenOf: []
  },
  economy: {
    privateAccount: false,
    bankCard: false,
    savingsAccount: false,
    fundAccount: false,
    investmentAccount: false,
    internetBank: false
  },
  guardians: [],
  deposits: {},
  id: '',
  guardianRef: '',
  signedBy: []
})

@Injectable()
export class GenericTypesService {
  public static getBankServices = (item: Record<string, boolean>): string[] => {
    const keys = GenericTypesService.getActiveKeys(item)
    return [
      {key: 'privateAccount', title: 'Privatkonto', active: false},
      {key: 'bankCard', title: 'Bankkort Visa Online', active: false},
      {key: 'savingsAccount', title: 'Sparkonto', active: false},
      {key: 'fundAccount', title: 'Fondkonto', active: false},
      {key: 'investmentAccount', title: 'Investeringskonto', active: false},
      {key: 'internetBank', title: 'Internetbanken Ung (inkl BankID och Swish)', active: false}
    ]
      .filter((bi: IBankService) => keys.indexOf(bi.key) !== -1)
      .map((bi: IBankService) => bi.title)
  }

  private static getActiveKeys(item: Record<string, boolean>): string[] {
    return Object.keys(item).filter((key: string) => item[key])
  }
}
